<template>
  <div>
    <div v-if="!userloading && user.login" class="pa-2 basic">
      <v-row dense align="center" justify="center">

        <v-col cols="12" class="limited">
          <v-row dense class="pa-2">
            <v-col cols="12">
              <v-container fluid v-if="loading" class="ma-10">
                <v-row dense>
                  <v-col cols="12">
                    <v-row class="align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <v-card outlined v-for="each in bugs" :key="each._id" class="pa-3 mt-2 body-2">
            <v-row dense>
              <v-col cols="8">
                <v-row dense>
                  <v-col cols="8" class="font-weight-bold body-2">
                    {{ each.email }}
                  </v-col>
                  <v-col cols="2" class="caption text-right">
                    {{ each.date }}
                    <span v-if="each.status == 0" class="grey--text">미확인</span>
                    <span v-if="each.status == 1" class="blue--text">진행중</span>
                    <span v-if="each.status == 2" class="red--text">완료</span>
                  </v-col>
                  <v-col cols="2">
                    <v-btn v-if="each.status == 0" color="blue" xSmall @click="set_inprogress(each._id)">진행중</v-btn>
                    <v-btn v-if="each.status == 1" color="red" xSmall @click="set_done(each._id)">완료</v-btn>
                  </v-col>
                  <v-col cols="12" class="font-weight-bold body-2">
                    {{ each.title }}
                  </v-col>
                  <v-col cols="12">
                    <v-btn v-for="(img, idx) in each.links.link" :key="idx" text xSmall :href="img" target="_blank">
                      <v-icon>link</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="caption">
                    {{ each.info }}
                  </v-col>
                  <v-col cols="12">
                    {{ each.description }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">

                <v-row dense>

                  <v-col cols="10">

                    <v-textarea
                      outlined
                      dense
                      flat
                      rows="3"
                      class="elevation-0"
                      v-model="comment[each._id]" />

                  </v-col>

                  <v-col cols="2">
                    <v-btn small color="primary" @click="write_comment(each._id)">
                      작성
                    </v-btn>
                  </v-col>

                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-row dense v-for="each_comment in each.comment" :key="each_comment._id" class="caption">
                      {{ each_comment.date}} : {{ each_comment.comment }}
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

        </v-col>
      </v-row>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import S3 from 'aws-sdk/clients/s3'
import AWS from 'aws-sdk/global';

export default {
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      userloading: "userloading",

    }),
  },
  data: () => {
    return {
      bugs: [],
      comment: {},
      loading: true,
      dialog: false,
      img_file: null,
      img_list: [],
      title: "",
      info: "",
      description: "",
      // ---- env 파일에 숨길 내용 -----
      albumBucketName: 'hibernating-image',
      bucketRegion: 'ap-northeast-2',
      IdentityPoolId: 'ap-northeast-2:fabc642c-21b5-4d9d-98d8-2376bfec4c71',
      albumName: 'test-album'
      // -------------------------------
    }
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_info();
    });
  },
  methods: {
    reload_info: async function() {
      this.bugs = [];
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const bugs_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/all', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each of bugs_.data ) {
          let t_ = each;
          t_.links = JSON.parse(each.links);
          t_.comment = [];
          this.bugs.push(t_);
        }

        this.loading = false;

        await this.reload_comment();
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }

    },
    reload_comment: async function() {
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const comments_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/comment/all', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each_bug of this.bugs ) {
          each_bug.comment = comments_.data[each_bug._id];
        }

      } else {
        setTimeout(() => {
          this.reload_comment();
        }, 500);
      }

    },
    connect_s3() {
      AWS.config.update({
        region: this.bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.IdentityPoolId
        })
      })
      const s3 = new S3({
        apiVersion: "2006-03-01",
        params: { Bucket: this.albumBucketName }
      })
      return s3
    },
    select_image: async function () {
      this.img_list.push(new File([this.img_file], this.$moment().format("YYYY-MM-DD-hh-mm-ss") + "_" +this.img_file.name));
      setTimeout(()=>{
        this.img_file = null;
      },300);
    },
    close: async function() {
      this.dialog = false;
    },
    save: async function() {

      let link_list = [];
      let stop = false;
      let uploaded = [];

      const s3 = this.connect_s3();

      let albumPhotosKey = encodeURIComponent(this.user.data.email) + "/"
      for (let each in this.img_list ) {
        let photoKey = albumPhotosKey + this.img_list[each].name;
        let upload = new S3.ManagedUpload({
          params: {
            Bucket: this.albumBucketName,
            Key: photoKey,
            Body: this.img_list[each],
            ACL: 'public-read'
          }
        })
        try {
          let r = await upload.promise();
          link_list.push(r.Location);
          uploaded.push(photoKey);
        } catch(e) {
          stop = true;
          console.log(e);
        }
      }

      if ( !stop ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/', {
          email: this.user.data.email,
          title: this.title,
          info: this.info,
          description: this.description,
          links: JSON.stringify({ link: link_list, key: uploaded }),
        }, {
          headers: {'id_token': id_token},
        });

        this.reload_info();

        this.title = "";
        this.info = "";
        this.description = "";
        this.img_file = null;
        this.img_list = [];

        this.dialog = false;
      } else {
        // 오류가 발생한 경우
        for ( let each in uploaded ) {
          try {
            let r = await s3.deleteObject({Key:each});
          } catch(e) {
            console.log(e);
          }
        }
      }
    },
    delete_bug: async function(_id, key) {
      if ( confirm("정말 삭제하시겠습니까?") ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.delete(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/', {
            data: {
              email: this.user.data.email,
              id: _id,
            },
            headers: {'id_token': id_token},
        });

        const s3 = this.connect_s3()

        for ( let each of key ) {
          try {
            let r = await s3.deleteObject({Key: each}).promise();
          } catch(e) {
            console.log(e);
          }
        }

        this.reload_info();

      }

    },
    set_inprogress: async function(_id) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/status', {
        email: this.user.data.email,
        id: _id,
        status: 1,
      }, {
        headers: {'id_token': id_token},
      });

      this.reload_info();
    },
    set_done: async function(_id) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/status', {
        email: this.user.data.email,
        id: _id,
        status: 2,
      }, {
        headers: {'id_token': id_token},
      });

      this.reload_info();
    },
    write_comment: async function(_id) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/comment/', {
        email: this.user.data.email,
        bug: _id,
        comment: this.comment[_id],
      }, {
        headers: {'id_token': id_token},
      });

      for ( let each_bug of this.bugs ) {
        if ( each_bug._id == _id ) {
          let t_ = {
            date: this.$moment().format("YYYY-MM-DD"),
            comment: this.comment[_id],
          };
          if ( each_bug.comment === undefined ) {
            each_bug.comment = [];
            each_bug.comment.push(t_);
          } else {
            each_bug.comment.unshift(t_);
          }
        }
        console.log(each_bug);
      }

      this.comment[_id] = "";

    }

  }

}
</script>

<style scoped>

.theme--dark.v-divider {
    border-color: #FBDE44FF !important;
}

.op_btn {
  position:absolute;
  right:0;
  top:0;
}

.op_btn::before {
  color: transparent;
}

.op_btn::hover {
  color: transparent;
}

</style>
